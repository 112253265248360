import React, { useState, useEffect } from 'react';
import './Courses.css';

function Courses() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await fetch('http://localhost:8000/courses'); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error('Error fetching courses:', error);
      // Handle error state or display a message to the user
    }
  };

  return (
    <div className="courses-container">
      <h2>Cursos</h2>
      <div className="courses-list">
        {courses.map(course => (
          <div key={course.idcurso} className="course-card">
            <h3>{course.ncurso}</h3>
            <p>{course.descripcion}</p>
            <p><strong>Docente:</strong> {course.nombre_docente}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Courses;
