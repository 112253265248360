import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Import your Firebase auth instance
import './LandingPage.css';
import teachingImage from '../images/imagenlp.png';
import student1Image from '../images/alumno1.png';
import student2Image from '../images/alumno2.png';
import student3Image from '../images/alumno3.png';

function LandingPage() {
  const [user, setUser] = useState(null); // State to hold the current user
  const [nombres, setNombres] = useState([]);

  const fetchNombres = async () => {
    try {
        const response = await fetch('http://localhost:3001/api/nombres');  // Cambiar la ruta a /api/nombres
        if (!response.ok) {
            throw new Error('Error al obtener los datos del servidor!!');  // Manejo del error si la respuesta no es OK
        }

        const data = await response.json();  // Convertir la respuesta a JSON
        setNombres(data);  // Asumimos que tienes un estado llamado setNombres para guardar los nombres
    } catch (error) {
        console.log('Error al obtener los nombres!!', error);  // Captura y log del error
    }
};

  useEffect(() => {
    // Firebase listener to check authentication state
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user); // User is signed in
      } else {
        setUser(null); // No user is signed in
      }
    });

    // Clean-up function
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Petición a la API para obtener los nombres
    const fetchNombres = async () => {
        try {
            const response = await fetch('http://localhost:3001/api/nombres'); // URL de tu API
            const data = await response.json();
            setNombres(data); // Guardar los nombres en el estado
        } catch (error) {
            console.error('Error al obtener los nombres:', error);
        }
    };

    fetchNombres(); // Llamada a la API
}, []); // Este efecto se ejecuta solo una vez cuando se monta el componente

  return (
    <div className="landing-page">
      <div className="hero-section">
        <div className="hero-text">
          <h1>Mejora tu desempeño académico hoy!</h1>
          <p>Si eres una persona con discapacidad auditiva nuestra página te ayudará a conocer tus puntos débiles académicamente y te recomendará qué hacer para mejorar tus notas!</p>
          {!user && <Link to="/signup" className="cta-button">Crear cuenta</Link>}
          {/* Display the 'Crear cuenta' button only if user is not logged in */}
        </div>
        <div className="hero-image">
          <img src={teachingImage} alt="Teaching" />
        </div>
      </div>
      <div className="students-section">
        <h3>Nuestros alumnos felices</h3>
        <div className="student-images">
          <img src={student1Image} alt="Student 1" />
          <img src={student2Image} alt="Student 2" />
          <img src={student3Image} alt="Student 3" />
        </div>
      </div>
    <div className='container'>
      {nombres.map((n, index) => (
        <div className='card' key={index}>
        <h3>{n.nombre}</h3>
        {/* Si tienes más campos como apellido o correo puedes añadirlos aquí */}
    </div>
  ))}
</div>
    </div>


  );
}

export default LandingPage;

