import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './Exam.css';

function Exam() {
  const navigate = useNavigate();
  const [questionsByEspecialidad, setQuestionsByEspecialidad] = useState([]);
  const [answers, setAnswers] = useState({});
  const [especialidades, setEspecialidades] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const responseQuestions = await fetch('http://localhost:8000/questions');
        if (!responseQuestions.ok) {
          throw new Error('Failed to fetch questions');
        }
        const questionsData = await responseQuestions.json();
        const questionsByEspecialidadMap = {};

        // Group questions by especialidad
        questionsData.forEach(question => {
          const idespecialidad = question.idespecialidad;
          if (!questionsByEspecialidadMap[idespecialidad]) {
            questionsByEspecialidadMap[idespecialidad] = {
              especialidad: idespecialidad,
              questions: [],
            };
          }
          questionsByEspecialidadMap[idespecialidad].questions.push(question);
        });

        setQuestionsByEspecialidad(Object.values(questionsByEspecialidadMap));
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    const fetchEspecialidades = async () => {
      try {
        const response = await fetch('http://localhost:8000/especialidades');
        if (!response.ok) {
          throw new Error('Failed to fetch especialidades');
        }
        const data = await response.json();
        const especialidadesMap = {};
        data.forEach(especialidad => {
          especialidadesMap[especialidad.idespecialidad] = especialidad.especialidad;
        });
        setEspecialidades(especialidadesMap);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching especialidades:', error);
        setLoading(false);
      }
    };

    fetchQuestions();
    fetchEspecialidades();
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(getAuth(), user => {
      if (!user) {
        // Redirect to login if no user is signed in
        navigate('/login');
      }
    });

    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts
  }, [navigate]);

  const handleAnswerChange = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
  };

  const handleSubmitExam = async () => {
    const allAnswered = questionsByEspecialidad.every(especialidad =>
      especialidad.questions.every(question => answers.hasOwnProperty(question.idpregunta))
    );

    if (!allAnswered) {
      setError('Please answer all the questions before submitting.');
      return;
    }

    try {
      const user = getAuth().currentUser;
      if (!user) {
        throw new Error('No user signed in.');
      }

      const token = await user.getIdToken();
      const response = await fetch('http://localhost:8000/submit-exam', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          answers: answers,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit exam');
      }

      navigate('/exam-result');
    } catch (error) {
      console.error('Error submitting exam:', error);
      setError('Failed to submit exam. Please try again.');
    }
  };

  const randomlyMarkAnswers = () => {
    const randomAnswers = {};

    questionsByEspecialidad.forEach(especialidad => {
      especialidad.questions.forEach(question => {
        const randomOption = Math.floor(Math.random() * 4); // Generate random index (0 to 3)
        const answerOptions = ['a', 'b', 'c', 'd'];
        randomAnswers[question.idpregunta] = answerOptions[randomOption];
      });
    });

    setAnswers(randomAnswers);
  };

  return (
    <div className="exam-container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2>Examen</h2>
          <button onClick={randomlyMarkAnswers}>Randomly Mark Answers</button>
          {questionsByEspecialidad.map((especialidadObj, index) => (
            <div key={index} className="especialidad-container">
              <h3>{especialidades[especialidadObj.especialidad]}</h3>
              {especialidadObj.questions.map(question => (
                <div key={question.idpregunta} className="question-container">
                  <p>{question.pregunta}</p>
                  <label>
                    <input
                      type="radio"
                      name={`question_${question.idpregunta}`}
                      value="a"
                      onChange={() => handleAnswerChange(question.idpregunta, 'a')}
                      checked={answers[question.idpregunta] === 'a'}
                    />
                    {question.opcion_a}
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`question_${question.idpregunta}`}
                      value="b"
                      onChange={() => handleAnswerChange(question.idpregunta, 'b')}
                      checked={answers[question.idpregunta] === 'b'}
                    />
                    {question.opcion_b}
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`question_${question.idpregunta}`}
                      value="c"
                      onChange={() => handleAnswerChange(question.idpregunta, 'c')}
                      checked={answers[question.idpregunta] === 'c'}
                    />
                    {question.opcion_c}
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`question_${question.idpregunta}`}
                      value="d"
                      onChange={() => handleAnswerChange(question.idpregunta, 'd')}
                      checked={answers[question.idpregunta] === 'd'}
                    />
                    {question.opcion_d}
                  </label>
                </div>
              ))}
            </div>
          ))}
          <div className="button-container">
            {error && <p className="error-message">{error}</p>}
            <button onClick={handleSubmitExam}>Enviar Examen </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Exam;

