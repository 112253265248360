import React, { useState, useEffect } from 'react';
import './Auth.css'; // Ensure you have a CSS file for styles

function Registro() {
  const [formData, setFormData] = useState({
    tipousuario: '',
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    dni: '',
    correo: '',
    contrasena: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:3001/api/registro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Usuario registrado con éxito');
      } else {
        alert('Error al registrar el usuario: ' + result.message);
      }
    } catch (error) {
      console.error('Error en la petición:', error);
      alert('Error al conectar con el servidor');
    }
  };

  return (
    <div className="auth-container">
      <h1>Crear cuenta</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Tipo de Usuario:</label>
          <select name="tipousuario" value={formData.tipousuario} onChange={handleChange} required>
            <option value="">Seleccione</option>
            <option value="Docente">Docente</option>
            <option value="Estudiante">Estudiante</option>
          </select>
        </div>

        <div>
          <label>Nombre:</label>
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Apellido Paterno:</label>
          <input
            type="text"
            name="apellido_paterno"
            value={formData.apellido_paterno}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Apellido Materno:</label>
          <input
            type="text"
            name="apellido_materno"
            value={formData.apellido_materno}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>DNI:</label>
          <input
            type="text"
            name="dni"
            value={formData.dni}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Correo:</label>
          <input
            type="email"
            name="correo"
            value={formData.correo}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Contraseña:</label>
          <input
            type="password"
            name="contrasena"
            value={formData.contrasena}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit">Crear cuenta</button>
      </form>
    </div>
  );
}

export default Registro;
